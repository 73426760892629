import React from "react"
import HeroSection from "../components/hero-section"
import AddressInput from "../components/address-input"

export default function PreBuild() {
  return (
    <HeroSection backgroundColor="#222b26">
      <h2 className="text-5xl text-white font-light tracking-wide">
        Let's build <span className="font-bradford">the future.</span>
      </h2>

      <AddressInput></AddressInput>
    </HeroSection>
  )
}
