import React from "react"

export default function ArrowSubmit({ fill }) {
  return (
    <svg
      style={{
        fill,
      }}
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.45 17.28"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect className="cls-1" y="7.56" width="18.4" height="2.16" />
          <polygon
            className="cls-1"
            points="22.45 7.2 14.58 0 11.41 0 19.36 7.2 22.45 7.2"
          />
          <polygon
            className="cls-1"
            points="11.41 17.28 14.57 17.28 22.45 10.08 19.39 10.08 11.41 17.28"
          />
        </g>
      </g>
    </svg>
  )
}
